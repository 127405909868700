@import './includes';

* {
  font-family: 'Noto Sans TC', sans-serif;
}

:root,
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  white-space: nowrap;

  @include scroll;
}

* {
  @include scroll;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $mainBgColor;
  font-size: 15px;
  color: $whiteColor;

  ::placeholder,
  ::-moz-placeholder,
  ::-webkit-input-placeholder {
    color: $btnGreyTextColor;
  }
  *:focus {
    outline: none;
  }
  pre,
  code,
  textarea {
    font-family: 'Noto Sans TC', sans-serif;
  }
}

#root {
  width: 100%;
  height: 100%;
}

// .page {
//   padding-top: 60px;
//   height: calc(100vh - 60px);
// }

.divider {
  @include flexCenter;
  width: 280px;
  height: 1px;
  background-color: #42484f;
  &:before {
    content: '';
    width: 15%;
    height: 5px;
    background-color: $greenTextColor;
  }
}
