@import './var';

@mixin btn-disabled {
  cursor: default;
  pointer-events: none;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mobile-screen {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin transition($size: 0.3) {
  transition: all #{$size}s ease-in-out;
}

@mixin scroll {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $greenTextColor;
    border-radius: 8px;
  }
}