$mainColor: #19b9c3;
$lightGreenColor: #50fec5;
$greenTextColor: #57cbcc;
$redColor: #db3a20;
$blushColor: #fc6b55;
$whiteColor: #fff;
$blackColor: #000;
$lightGreyTextColor: #d5d5d5;
$btnGreyTextColor: #777;
$btnBorderColor: #494949;

$mainBgColor: #353b43;
$darkBgColor: #292f36;
$uerboardTextBgColor: #141414;
$uerboardTextBorderColor: #707070;
$userboardBgColor: #1e1e1e;
$uerboardSideBarColor: #252526;
$uerboardSideBarGrey: #bbbbbb;
$textBorderColor: #707070;
$containerBgColor: #1e1e1e;


$uerboardTextGrayColor: #afafaf;

@mixin btn-gradient-color {
  background-image: linear-gradient(to right, #50ffc5, #19b9c3);
  background-image: linear-gradient(120deg, #50FFC5 , #19B9C3 60%) padding-box;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
